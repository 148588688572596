<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button>
        </page-title>
        <div class="filters-container">
            <el-form :model="formData" :inline="true">
                <el-form-item label="创建时间">
                    <created-at-picker v-model="formData.createdAt" @input="initData" name="创建"></created-at-picker>
                </el-form-item>
                <!-- <el-form-item label="用户ID">
                    <el-input v-model="formData.userId"       placeholder="用户ID" clearable=""  ></el-input>
                </el-form-item> -->
                <el-form-item label="转赠订单ID">
                    <el-input v-model="formData.giftOrderId"       placeholder="转赠订单ID" clearable="" ></el-input>
                </el-form-item>
                <el-form-item label="购买订单ID">
                    <el-input v-model="formData.orderId"       placeholder="购买订单ID" clearable="" ></el-input>
                </el-form-item>
                <el-form-item label="订单编号">
                    <el-input v-model="formData.orderNo"       placeholder="订单编号"  clearable=""  ></el-input>
                </el-form-item>
                <el-form-item label="交易流水">
                    <el-input v-model="formData.transactionId"       placeholder="交易流水" clearable=""  ></el-input>
                </el-form-item>

                <el-form-item label="支付平台">
                    <el-select  v-model="formData.payPlatform"  :clearable="true"  >
                        <el-option v-for="(value,key) in payPlatformMap" :label="value"  :value="key" :key="key"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="支付方式">
                    <el-select  v-model="formData.payMethod"  :clearable="true" @change="initData">
                        <el-option v-for="(value,key) in payMethodMap" :label="value"  :value="key" :key="key"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="支付状态">
                    <el-select  v-model="formData.status"  :clearable="true" @change="initData">
                        <el-option v-for="(value,key) in payStatusMap" :label="value"  :value="key" :key="key"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="订单状态">
                    <el-select v-model="formData.orderStatus" placeholder="筛选状态" clearable @change="initData" style="margin-right: 10px">
                        <el-option
                            v-for="(value,key) in orderStatusMap"
                            :key="key"
                            :value="key"
                            :label="value"
                        ></el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <div class="tool-wrapper">
                <div class="tool-right">
                    <el-tag>总金额({{moneyInfo.payAmt || 0}}￥)</el-tag>
                </div>
                 <el-input
                    placeholder="搜索..."
                    v-model="search"
                    clearable
                    class="filter-item search"
                    @keyup.enter.native="initData"
                >
                    <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
                </el-input>

            </div>


        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="userId" label="用户ID" > </el-table-column>
            <el-table-column prop="giftOrderId" label="转赠订单ID"  min-width="80"  show-overflow-tooltip> </el-table-column>
            <el-table-column prop="orderId" label="购买订单ID"   min-width="160" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="orderNo" label="订单编号" min-width="160"  show-overflow-tooltip> </el-table-column>
            <el-table-column prop="transactionId" label="交易流水号" min-width="100"  show-overflow-tooltip> </el-table-column>
            <el-table-column prop="goodsTitle" label="商品标题"  min-width="100"  show-overflow-tooltip> </el-table-column>

            <el-table-column prop="payAmt" label="支付金额" > </el-table-column>
            <el-table-column prop="payMethod" label="支付方式" width="100">
                <template slot-scope="scope">
                    <el-tag :type="payMethodMap[scope.row.payMethod].type" v-if="payMethodMap[scope.row.payMethod]">{{payMethodMap[scope.row.payMethod]}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="支付状态" width="100">
                <template slot-scope="scope">
                    <el-tooltip placement="top" :content="scope.row.refundReason || '没有问题'">
                    <el-tag :type="payStatusMap[scope.row.status].type" v-if="payStatusMap[scope.row.status]">{{payStatusMap[scope.row.status]}}</el-tag>
                    </el-tooltip>
                </template>
            </el-table-column>

           <el-table-column prop="payPlatform" label="支付平台" min-width="100">
                <template slot-scope="scope">
                    <el-tag :type="payPlatformMap[scope.row.payPlatform].type" v-if="payPlatformMap[scope.row.payPlatform]">{{payPlatformMap[scope.row.payPlatform]}}</el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="payTime" label="支付时间" width="140">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>
            <el-table-column prop="notifyTime" label="通知时间" width="140"> </el-table-column>


             <el-table-column prop="createdAt" label="创建时间" width="140">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>


            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini"  @click="batchRefund(scope.row.orderNo)" v-if="scope.row.status == 'PENDING_REFUND'||scope.row.status == 'HAVE_PAID' ">退款</el-button>
                    <el-button type="warning" size="mini"  @click="sendNotifyOrder(scope.row.orderNo)" v-if="scope.row.status == 'HAVE_PAID' && scope.row.orderStatus == 'NOT_PAID' ">完成订单</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import pageableTable from '@/mixins/pageableTable';
import SortableHeader from '../components/SortableHeader.vue';
import {payStatusMap,payMethodMap, payPlatformMap,orderStatusMap} from "@/utils/constant.js";

export default {
    components: { SortableHeader },
    name: 'PaymentInfoList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/paymentInfo/all',
            downloading: false,
            formData:{
                 createdAt: "",
                 userId:"",
                 giftOrderId:"",
                 orderId:"",
                 orderNo:"",
                 transactionId:"",
                 status: null,
                 orderStatus: null,
                 payPlatform:"",
            },
            payStatusMap,
            payMethodMap,
            payPlatformMap,
            orderStatusMap,
            moneyInfo: {}
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return {
                search: this.search,
                query: { del: false, ...this.formData}
            };
        },
        afterGetData(){
            //计算金额信息
            this.calculateMoneyInfo();
        },
        download() {
            this.downloading = true;
            let reqData = this.beforeGetData();
            reqData.size = 10000;
            this.$axios
                .request({
                    method:"get",
                    url: '/paymentInfo/excel',
                    responseType: 'blob',
                    data: reqData
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        batchRefund(orderNo){
            var orderNoList = [orderNo];
            this.fetchingData = true;
            this.$http
                .post("/paymentInfo/batchRefund", orderNoList, { body: 'json' })
                .then(res => {
                    this.fetchingData = false;
                    this.$message.success("操作成功，请等待退款完成");
                    this.initData();
                })
                .catch(e => {
                    this.fetchingData = false;
                    this.$message.error(e.error);
                });
        },
        sendNotifyOrder(orderNo){
            this.fetchingData = true;
            this.$http
                .post("/paymentInfo/sendNotifyOrder", {orderNo}, { body: 'json' })
                .then(res => {
                    this.fetchingData = false;
                    this.$message.success("操作成功");
                    this.initData();
                })
                .catch(e => {
                    this.fetchingData = false;
                    this.$message.error(e.error);
                });
        },
        calculateMoneyInfo(){
            let reqData = this.beforeGetData();
            this.$axios
                .request({
                    method:"post",
                    url: '/paymentInfo/calculateMoneyInfo',
                    data: reqData
                })
                .then(res => {
                   this.moneyInfo = res.data || {};
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },

    }
};
</script>
<style lang="less" scoped>
.tool-wrapper{
    display:block;
    overflow: hidden;

    .tool-right{
        float:left;
    }
}
</style>
